import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Article from '../components/Article';

import './news-list.scss';

const PaginationLink = (props) => {
  if (!props.test) {
    return <Link to={`/news/${props.url}`}>{props.text}</Link>;
  } else {
    return <span disabled>{props.text}</span>;
  }
};

export default class NewsIndexPage extends React.Component {
  render() {
    const { location, pageContext } = this.props;
    const { group, index, pageCount, first, last } = pageContext;
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString();
    const nextUrl = (index + 1).toString() + '/';

    const pageNumbers = new Array(pageCount)
      .fill(undefined)
      .map((_, index) => index + 1);

    return (
      <Layout>
        <Helmet>
          <title>News | STINGS</title>
          <meta property="og:title" content="News | STINGS" />
          <meta name="twitter:title" content="News | STINGS"></meta>
        </Helmet>

        <div className="content">
          <div className="content__inner">
            <section className="article">
              <div className="section-heading-blue">
                <h2>NEWS</h2>
              </div>
              <section className="article-content">
                <Article posts={group} />
              </section>
            </section>
            <div className="pagination">
              {!first && (
                <PaginationLink test={first} url={previousUrl} text="<" />
              )}
              {pageNumbers.map((number) => {
                const isActive =
                  location.pathname.indexOf(number) > -1 ||
                  (location.pathname === '/news/' && number === 1);
                return (
                  <PaginationLink
                    test={isActive}
                    url={`/${number === 1 ? '' : number}`}
                    text={number}
                  />
                );
              })}
              {!last && <PaginationLink test={last} url={nextUrl} text=">" />}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

NewsIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};
