import React from 'react';
import { Link } from 'gatsby';
import './index.scss';

const Article = ({ posts }) => {
  return (
    <ul className="article-list">
      {posts.map(({ node: post }) => (
        <li key={post.id}>
          <div className="heading__article-list">
            <span>{post.frontmatter.date}</span>
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </div>
          <div className="img__article-list">
            <Link className="img__article-list__inner" to={post.fields.slug}>
              <img
                src={post.frontmatter.thumbnail.childImageSharp.fluid.src}
                alt="ArticleThumbnail"
              />
            </Link>
            <Link className="square-btn" to={post.fields.slug}>
              <span>READ MORE ></span>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Article;
